import { EXPLORER_TIER_ID, FREE_TIER_ID } from '@/constants'
import useActivePlan from '@/hooks/useActivePlan'
import useActiveTobPlan from '@/hooks/useActiveTobPlan'
import { whisper } from '@/utils'
import { spanStatusfromHttpCode } from '@sentry/nextjs'
import { useMemo } from 'react'

export const formatEstimatedTime = (seconds: number) => {
  if (!seconds) {
    return null
  }
  if (seconds < 60) {
    return (
      <span>
        less than
        <span className='font-bold px-1'>1</span>
        minute
      </span>
    )
  }
  if (seconds <= 59 * 60) {
    return (
      <span>
        <span className='font-bold px-1'>{Math.ceil(seconds / 60)}</span>
        minutes
      </span>
    )
  }

  if (seconds <= 60 * 60) {
    return (
      <span>
        <span className='font-bold px-1'>1</span>
        hour
      </span>
    )
  }

  return (
    <span>
      <span className='font-bold px-1'>{Math.ceil(seconds / 3600)}</span>
      hours
    </span>
  )
}

export default function usePendingDisplay({
  creation,
  jobStatus,
}: {
  creation: haiper.Creation
  jobStatus: haiper.JobStatus | null
}) {
  const { data: activePlan, loading: planLoading } = useActivePlan()
  const { data: tobPlan, loading: tobPlanLoading } = useActiveTobPlan()

  const showEstimatedTime = !!jobStatus?.queued_esitmate_time
  const estimatedTime = useMemo(() => {
    return formatEstimatedTime(jobStatus?.queued_esitmate_time ?? 0)
  }, [jobStatus?.queued_esitmate_time])

  const isFreePlan =
    !planLoading && !tobPlanLoading && (!activePlan || activePlan.is_free) && (!tobPlan || tobPlan.is_free)

  const showProgress = !!jobStatus?.progress
  const showPending = !['succeed', 'error'].includes(jobStatus?.status ?? '') && !showProgress

  const showUpgradeButtonForFastGeneration = useMemo(() => {
    if (!showPending) {
      return false
    }

    if (creation.queue_tier === 'turbo') {
      // highest level
      return false
    }

    // if pro+ user
    // there's condition that pro user has a relax/fast level generation, but we won't show the upgrade button
    // if (
    //   activePlan &&
    //   !activePlan.is_free &&
    //   activePlan.tier_id !== FREE_TIER_ID &&
    //   activePlan.tier_id !== EXPLORER_TIER_ID
    // ) {
    //   return false
    // }

    // only show upgrade button if the estimated time is more than 10 minutes
    // return !!jobStatus?.queued_esitmate_time && jobStatus.queued_esitmate_time >= 10 * 60
    // return true
    return isFreePlan || activePlan?.tier_id === EXPLORER_TIER_ID
  }, [creation, activePlan, isFreePlan, showPending])

  const showUpgradeLinkForRateLimit = useMemo(() => {
    return showProgress && isFreePlan
  }, [showProgress, isFreePlan])

  return {
    showEstimatedTime,
    estimatedTime,
    showUpgradeButtonForFastGeneration,
    showUpgradeLinkForRateLimit,
    showPending,
    showProgress,
  }
}
