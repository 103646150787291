'use client'
import { cls } from '@/utils'
import { capitalize } from 'lodash-es'
import { useMemo } from 'react'
import { sortedBreakpointKeys, useBreakpoints } from './useBreakPoint'

/**
 * return the number of gap size
 * @param className - the class name of the grid. eg. 'grid grid-cols-2 md:grid-cols-3 desktop:grid-cols-4 gap-4 md:gap-6'
 */
export default function useGapSize(className: string) {
  const splittedClassnames: string[] = useMemo(() => {
    return cls(className).split(' ')
  }, [className])

  const breakpoints = useBreakpoints()

  const defaultGapSize: number = useMemo(() => {
    const regexp = /^gap-(\d+)$/
    const classname = splittedClassnames.find((c) => regexp.test(c))
    const match = classname?.match(regexp)
    const result = match ? Math.floor(Number(match[1])) : 1
    return Math.max(1, result)
  }, [splittedClassnames])

  const result = useMemo(() => {
    let value = defaultGapSize
    for (const key of sortedBreakpointKeys) {
      const regexp = new RegExp(`${key}:gap-(\\d+)`)
      const belowKey = `isBelow${capitalize(key)}`

      const isBelow = (breakpoints as any)[belowKey] as boolean

      if (isBelow) {
        break
      }

      const classname = splittedClassnames.find((c) => regexp.test(c))
      const match = classname?.match(regexp)
      const cols = match ? Math.floor(Number(match[1])) : 1
      value = Math.max(value, cols)
    }
    return value
  }, [breakpoints, splittedClassnames, defaultGapSize])

  // tailwindcss gap-x to px
  return result * 4
}
