import {
  createContext,
  useContext,
  forwardRef,
  useState,
  useCallback,
  KeyboardEvent,
  useEffect,
  HTMLAttributes,
  ComponentProps,
  useMemo,
  MouseEvent,
} from 'react'
import useEmblaCarousel, { type UseEmblaCarouselType } from 'embla-carousel-react'
import Autoplay, { AutoplayType } from 'embla-carousel-autoplay'
import { cls, whisper } from '@/utils'
import { Button } from '@/components/ui/button'
import IconChevronLeft from '@haiper/icons-svg/icons/outline/chevron-left.svg'
import IconChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'

type CarouselApi = UseEmblaCarouselType[1]
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>
type CarouselOptions = UseCarouselParameters[0]
type CarouselPlugin = UseCarouselParameters[1]

export type CarouselProps = {
  opts?: CarouselOptions
  plugins?: CarouselPlugin
  orientation?: 'horizontal' | 'vertical'
  setApi?: (api: CarouselApi) => void
  pauseOnHover?: boolean
  autoPlay?: boolean
  autoPlayDelay?: number
}

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0]
  api: ReturnType<typeof useEmblaCarousel>[1]
  scrollPrev: () => void
  scrollNext: () => void
  canScrollPrev: boolean
  canScrollNext: boolean
} & CarouselProps

const CarouselContext = createContext<CarouselContextProps | null>(null)

function useCarousel() {
  const context = useContext(CarouselContext)

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />')
  }

  return context
}

const Carousel = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & CarouselProps>(
  (
    {
      orientation = 'horizontal',
      opts,
      setApi,
      plugins,
      className,
      children,
      autoPlay,
      autoPlayDelay,
      pauseOnHover,
      onMouseEnter,
      onMouseLeave,
      ...props
    },
    ref,
  ) => {
    const autoPlayPlugin: AutoplayType | null = useMemo(() => {
      if (!autoPlay) {
        return null
      }

      return Autoplay({
        delay: autoPlayDelay ?? 4000,
      })
    }, [autoPlay, autoPlayDelay])

    const realPlugins = useMemo(() => {
      if (autoPlayPlugin && autoPlay) {
        return [...(plugins ?? []), autoPlayPlugin]
      }

      return plugins
    }, [autoPlayPlugin, plugins, autoPlay])

    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === 'horizontal' ? 'x' : 'y',
      },
      realPlugins,
    )
    const [canScrollPrev, setCanScrollPrev] = useState(false)
    const [canScrollNext, setCanScrollNext] = useState(false)

    const onSelect = useCallback((api: CarouselApi) => {
      if (!api) {
        return
      }

      setCanScrollPrev(api.canScrollPrev())
      setCanScrollNext(api.canScrollNext())
    }, [])

    const scrollPrev = useCallback(() => {
      api?.scrollPrev()
    }, [api])

    const scrollNext = useCallback(() => {
      api?.scrollNext()
    }, [api])

    const handleMouseEnter = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        if (pauseOnHover && autoPlay) {
          autoPlayPlugin?.stop()
        }

        onMouseEnter?.(event)
      },
      [autoPlayPlugin, pauseOnHover, onMouseEnter, autoPlay],
    )

    const handleMouseLeave = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        if (pauseOnHover && autoPlay) {
          autoPlayPlugin?.play()
          // setTimeout(scrollNext, 300)
        }

        onMouseLeave?.(event)
      },
      [autoPlayPlugin, pauseOnHover, onMouseLeave, autoPlay],
    )

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault()
          scrollPrev()
        } else if (event.key === 'ArrowRight') {
          event.preventDefault()
          scrollNext()
        }
      },
      [scrollPrev, scrollNext],
    )

    useEffect(() => {
      if (!api || !setApi) {
        return
      }

      setApi(api)
    }, [api, setApi])

    useEffect(() => {
      if (!api) {
        return
      }

      onSelect(api)
      api.on('reInit', onSelect)
      api.on('select', onSelect)

      return () => {
        api?.off('select', onSelect)
      }
    }, [api, onSelect])

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation: orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
        }}
      >
        <div
          ref={ref}
          className={cls('relative', className)}
          role='region'
          aria-roledescription='carousel'
          onKeyDownCapture={handleKeyDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    )
  },
)
Carousel.displayName = 'Carousel'

const CarouselContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel()

  return (
    <div ref={carouselRef} className='overflow-hidden h-full'>
      <div
        ref={ref}
        className={cls('flex', orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col', className)}
        {...props}
      />
    </div>
  )
})
CarouselContent.displayName = 'CarouselContent'

const CarouselItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      role='group'
      aria-roledescription='slide'
      className={cls('min-w-0 shrink-0 grow-0 basis-full', className)}
      {...props}
    />
  )
})
CarouselItem.displayName = 'CarouselItem'

const CarouselPrevious = forwardRef<HTMLButtonElement, ComponentProps<typeof Button>>(
  ({ className, variant = 'outline', size = 'icon', ...props }, ref) => {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel()

    return (
      <Button
        ref={ref}
        variant={variant}
        size={size}
        className={cls(
          'absolute size-10 md:size-12 rounded-full border border-border border-solid bg-surface hover:bg-surface-hover text-icon disabled:pointer-events-auto',
          orientation === 'horizontal'
            ? 'left-4 md:left-10 top-1/2 -translate-y-1/2'
            : 'top-4 md:top-10 left-1/2 -translate-x-1/2 rotate-90',
          canScrollPrev ? '' : 'cursor-not-allowed',
          className,
        )}
        disabled={!canScrollPrev}
        onClick={scrollPrev}
        {...props}
      >
        <IconChevronLeft className='size-6' />
        <span className='sr-only'>Previous slide</span>
      </Button>
    )
  },
)
CarouselPrevious.displayName = 'CarouselPrevious'

const CarouselNext = forwardRef<HTMLButtonElement, ComponentProps<typeof Button>>(
  ({ className, variant = 'outline', size = 'icon', ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel()

    return (
      <Button
        ref={ref}
        variant={variant}
        size={size}
        className={cls(
          'absolute size-10 md:size-12 rounded-full border border-border border-solid bg-surface hover:bg-surface-hover text-icon',
          orientation === 'horizontal'
            ? 'right-4 md:right-10 top-1/2 -translate-y-1/2'
            : 'bottom-4 md:bottom-10 left-1/2 -translate-x-1/2 rotate-90',
          canScrollNext ? '' : 'cursor-not-allowed',
          className,
        )}
        disabled={!canScrollNext}
        onClick={scrollNext}
        {...props}
      >
        <IconChevronRight className='size-6' />
        <span className='sr-only'>Next slide</span>
      </Button>
    )
  },
)
CarouselNext.displayName = 'CarouselNext'

export { type CarouselApi, Carousel, CarouselContent, CarouselItem, CarouselPrevious, CarouselNext }
