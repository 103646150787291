import { cls } from '@/utils'
import IconError from '@haiper/icons-svg/icons/outline/x-circle.svg'

interface CanceledJobProps {
  showMode: 'mobile' | 'grid' | 'single'
}

const CanceledJob = ({ showMode }: CanceledJobProps) => {
  return (
    <div
      className={cls(
        'flex justify-center items-center flex-col bg-surface w-full border border-solid border-border rounded-lg aspect-video gap-2 @md:gap-3',
      )}
    >
      <IconError className={cls('text-icon-subdued', showMode === 'single' ? 'size-12' : 'size-12')} />
      <div className='w-full flex justify-center items-center'>Canceled</div>
    </div>
  )
}

export default CanceledJob
