import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { cls } from '@/utils'
import { useWindowSize } from 'usehooks-ts'

export interface EllipsisProps {
  text: string
  ellipsis: ReactNode
  className?: string
}

export default function Ellipsis({ text, ellipsis, className }: EllipsisProps) {
  const [ellipsisActive, setEllipsisActive] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const hiddenSpanRef = useRef<HTMLSpanElement>(null)

  const checkEllipsis = useCallback(() => {
    // const el = ref.current
    const el = hiddenSpanRef.current
    if (el) {
      setEllipsisActive(el.scrollWidth > el.clientWidth)
    }
  }, [])

  const lazyCheckEllipsis = useCallback(() => {
    requestAnimationFrame(() => {
      setTimeout(() => {
        checkEllipsis()
      }, 0)
    })
  }, [checkEllipsis])

  const { width, height } = useWindowSize()

  useEffect(() => {
    lazyCheckEllipsis()
  }, [text, width, height, lazyCheckEllipsis])

  return (
    <div
      ref={ref}
      className={cls('flex relative gap-1', className)}
      onResize={checkEllipsis}
    >
      <span
        className={cls(
          'relative w-0 flex-1 whitespace-nowrap',
          ellipsisActive ? 'overflow-hidden' : null,
        )}
      >
        {text}
        {ellipsisActive ? (
          <div className='w-4 bg-gradient-to-r from-transparent to-surface absolute top-0 right-0 h-full' />
        ) : null}
      </span>
      {ellipsisActive ? (
        <div className='w-max'>{ellipsisActive ? ellipsis : null}</div>
      ) : null}
      <span
        ref={hiddenSpanRef}
        className='absolute inset-x-0 whitespace-nowrap h-0 opacity-0 pointer-events-none'
        aria-label='hidden span for checking ellipsis'
      >
        {text}
      </span>
    </div>
  )
}
