'use client'
import { cls } from '@/utils'
import { capitalize } from 'lodash-es'
import { useMemo } from 'react'
import { sortedBreakpointKeys, useBreakpoints } from './useBreakPoint'

/**
 * return the number of columns in the grid.
 * @param className - the class name of the grid. eg. 'grid grid-cols-2 md:grid-cols-3 desktop:grid-cols-4 gap-4'
 */
export default function useGridColumns(className: string) {
  const splittedClassnames: string[] = useMemo(() => {
    return cls(className).split(' ')
  }, [className])

  const breakpoints = useBreakpoints()

  const defaultCols: number = useMemo(() => {
    const regexp = /^grid-cols-(\d+)$/
    const classname = splittedClassnames.find((c) => regexp.test(c))
    const match = classname?.match(regexp)
    const result = match ? Math.floor(Number(match[1])) : 1
    return Math.max(1, result)
  }, [splittedClassnames])

  const result = useMemo(() => {
    let value = defaultCols
    for (const key of sortedBreakpointKeys) {
      const regexp = new RegExp(`${key}:grid-cols-(\\d+)`)
      const belowKey = `isBelow${capitalize(key)}`

      const isBelow = (breakpoints as any)[belowKey] as boolean

      if (isBelow) {
        break
      }

      const classname = splittedClassnames.find((c) => regexp.test(c))
      const match = classname?.match(regexp)
      const cols = match ? Math.floor(Number(match[1])) : 1
      value = Math.max(value, cols)
    }
    return value
  }, [breakpoints, splittedClassnames, defaultCols])

  return result
}
