import { cls } from '@/utils'
import IconGoldMedal from '@/public/assets/gold-medal.svg'
import SilverMedal from '@/public/assets/silver-medal.svg'
import BronzeMedal from '@/public/assets/bronze-medal.svg'

export interface PrizeProps {
  className?: string
  prize: haiper.MarketingEventSubmission['prize']
}

const iconMap = {
  gold: IconGoldMedal,
  silver: SilverMedal,
  bronze: BronzeMedal,
} as const

export default function Prize({ className, prize }: PrizeProps) {
  const Icon = (iconMap as any)[prize as any]
  if (!Icon) return null

  return <Icon className={cls('size-10', className)} />
}
