import { useBreakpoint } from '@/hooks/useBreakPoint'
import useGapSize from '@/hooks/useGapSize'
// import useGridColumns from '@/hooks/useGridColumns'
import useMagicGrid, { MagicGridProps } from '@/hooks/useMagicGrid'
import { cls, whisper } from '@/utils'
import { flatten } from 'lodash-es'
import { nanoid } from 'nanoid'
import { forwardRef, PropsWithChildren, useMemo, useRef, useEffect, CSSProperties } from 'react'

export interface WaterfallProps extends Omit<MagicGridProps, 'container'> {
  className?: string
  style?: CSSProperties
}

const MagicWaterfall = forwardRef<HTMLDivElement, PropsWithChildren<WaterfallProps>>(
  ({ className, style, children, maxColumns = 4, ...props }, ref) => {
    const innerRef = useRef<HTMLDivElement | null>(null)
    const randomId = useMemo(
      () =>
        nanoid()
          .replace(/[^a-zA-Z0-9:_-]/, '')
          .replace(/^/, 'n'),
      [],
    )
    const { isBelowMd } = useBreakpoint('md')

    const splittedClassnames: string[] = useMemo(() => {
      return cls(className).split(' ')
    }, [className])

    const gapClassName = useMemo(() => {
      const defaultGap = 'gap-4'
      const gapClassnames = splittedClassnames.filter((c) => /:?gap-/.test(c))
      return gapClassnames.length ? gapClassnames.join(' ') : defaultGap
    }, [splittedClassnames])

    const gapSize = useGapSize(gapClassName)

    const parsedClassName = useMemo(() => {
      return splittedClassnames
        .filter((c) => !c.startsWith('gap-') && !c.includes('grid-cols-') && c !== 'grid')
        .join(' ')
    }, [splittedClassnames])

    // const cols = useGridColumns(className ?? '')
    const flattedChildren = useMemo(
      () => flatten(Array.isArray(children) ? children : [children]).filter(Boolean),
      [children],
    )

    const magicGrid = useMagicGrid({
      items: flattedChildren.length,
      animate: false,
      gutter: gapSize,
      center: false,
      useMin: true,
      ...props,
      container: `#${randomId}`,
    })

    useEffect(() => {
      magicGrid?.positionItems()
    }, [flattedChildren, magicGrid])

    useEffect(() => {
      if (magicGrid) {
        magicGrid.columns = props.columns ?? false
        magicGrid.initialPaddings = props.initialPaddings ?? undefined
        magicGrid.positionItems()
      }
    }, [magicGrid, props.columns, props.initialPaddings])

    if (!Array.isArray(children)) {
      return <div className={cls('', className)}>{children}</div>
    }

    return (
      <div
        ref={(node) => {
          innerRef.current = node
          if (typeof ref === 'function') {
            ref(node)
          } else if (ref) {
            ref.current = node
          }
        }}
        id={randomId}
        className={cls('w-full transition-none', parsedClassName, gapClassName)}
        style={style}
      >
        {children}
      </div>
    )
  },
)

MagicWaterfall.displayName = 'MagicWaterfall'
export default MagicWaterfall
