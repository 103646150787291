import type {
  Pagination,
  PaginationQuery,
  SegmentQuery,
  Spotlight,
  SpotlightFormData,
  SpotlightQuery,
  Segment,
  SpotlightsListQuery,
} from '@/types'
import { rest } from '@/utils'

export const getSpotlights = async (params?: SpotlightQuery): Promise<Spotlight[]> => {
  const res = await rest.get('/v1/spotlights', { params })
  return res.data
}

export const getSpotlightsList = async (params: SpotlightsListQuery): Promise<Pagination<Spotlight>> => {
  const { type, ...query } = params
  const res = await rest.get(`/v1/spotlights/${type}`, { params: query })
  return res.data
}

export const postSpotlight = async (data: SpotlightFormData): Promise<Spotlight> => {
  const res = await rest.post('/v1/spotlight', data)
  return res.data
}

export const likeSpotlight = async (data: { spotlight_id: string; is_like: boolean }): Promise<void> => {
  const res = await rest.post('/v1/spotlight/commits/like', data)
}

export const getSpotlight = async (id: string): Promise<Spotlight> => {
  const res = await rest.get(`/v1/spotlight/${id}`)
  return res.data
}

export const getUserSpotlights = async (
  params: PaginationQuery<{ user_id: string }>,
): Promise<Pagination<Spotlight>> => {
  const { user_id, ...others } = params
  const res = await rest.get(`/v1/user/${user_id}/spotlight`, {
    params: others,
  })
  return res.data
}

export const getUserSpotlightsSegment = async (
  params: SegmentQuery<{ user_id: string }>,
): Promise<Segment<Spotlight>> => {
  const { user_id, ...others } = params
  const res = await rest.get(`/v1/user/${user_id}/spotlight`, {
    params: others,
  })
  return res.data
}

export const expandSpotlights = async (ids: string[]): Promise<Record<string, Spotlight>> => {
  const res = await rest.post('/v1/spotlights/expand', { ids })
  return res.data
}
