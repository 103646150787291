import { useEffect, useRef } from 'react'
import MagicGrid, { MagicGridProps } from '@haiper/magic-grid'

export type { MagicGridProps }

const useMagicGrid = (props: MagicGridProps) => {
  const gridRef = useRef<MagicGrid | null>(null)
  const { container } = props

  useEffect(() => {
    if (!container) {
      throw new Error('Container name or element is required')
    }

    if (!gridRef.current) {
      gridRef.current = new MagicGrid({ ...props, items: props.items || 1, static: false })
      gridRef.current.listen()
      return
    }

    const grid = gridRef.current
    const currentContainer: HTMLElement | null = document.querySelector(grid.containerClass)
    const containerChanged = grid.container !== currentContainer

    if (currentContainer && containerChanged) {
      grid.setContainer(currentContainer)
    }
  })

  return gridRef.current
}

export default useMagicGrid
