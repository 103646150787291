import { getSpotlights, getUserSpotlightsSegment } from '@/service/spotlight.service'
import { Skipable, SpotlightQuery } from '@/types'
import useService, { useCachedService } from '@banyudu/use-service'
import useInfinite from './useInfinite'

const skipableGetSpotlights = async (params?: Skipable<SpotlightQuery>) => {
  const { skip, ...restParams } = params ?? {}
  if (skip) return null

  const res = await getSpotlights(restParams)
  return res
}

// const useSpotlights = useService(
//   skipableGetSpotlights,
//   (params) => !params?.skip,
// )

export const useCachedSpotlights = useCachedService(skipableGetSpotlights, (params) => !params?.skip)

// const useUserSpotlights = useService(getUserSpotlights, (params) =>
//   Boolean(params?.user_id),
// )

const useUserSpotlightsSegment = useService(getUserSpotlightsSegment, (params) => Boolean(params?.user_id))

export const useInfiniteUserSpotlightsSegment = useInfinite(useUserSpotlightsSegment, {
  rowKey: 'spotlight_id',
})

// export default useSpotlights
