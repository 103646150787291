import { detect as detectBrowser } from 'detect-browser'
import { atom, useAtomValue } from 'jotai'

type BrowserInfo = ReturnType<typeof detectBrowser>

function getBrowserInfo(): BrowserInfo | null {
  if (typeof window === 'undefined') {
    return null
  }
  return detectBrowser()
}

const browserInfoAtom = atom<BrowserInfo | null>(getBrowserInfo)

const useBrowser = (): BrowserInfo => {
  const browserInfo = useAtomValue(browserInfoAtom)
  return browserInfo
}

export default useBrowser
