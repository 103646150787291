import React, { ReactNode, useCallback, useEffect, useRef } from 'react'
import { cls } from '@/utils'

export interface RadialProgressProps {
  progress: number
  className?: string
  progressClassname?: string
  baseCircleClassname?: string
  text?: string | ReactNode
  size?: number
  smooth?: boolean // smooth animation
  duration?: number // animation duration
}

const formatProgress = (progress: number) => {
  return Math.min(Math.max(Math.round(Number(progress) || 0), 0), 100)
}

const RadialProgress = ({
  progress,
  className,
  progressClassname,
  baseCircleClassname,
  text,
  size = 64,
  smooth,
  duration = 5000,
}: RadialProgressProps) => {
  const parsedProgress = formatProgress(progress)
  const radius = Math.round((size - 4) / 2)
  const circumference = 2 * Math.PI * radius

  const svgCircleRef = useRef<SVGCircleElement>(null)
  const animationRef = useRef<number>()
  const lastProgressRef = useRef<number>(0)
  const progressRef = useRef<number>(0)
  const spanRef = useRef<HTMLSpanElement>(null)

  const calculateOffset = useCallback(() => {
    const offset = circumference - (progressRef.current / 100) * circumference
    return offset
  }, [circumference])

  const applyProgress = useCallback(() => {
    const offset = calculateOffset()
    svgCircleRef.current?.setAttribute('stroke-dashoffset', String(offset))
    if (spanRef.current) {
      spanRef.current.innerText = `${formatProgress(progressRef.current)}%`
    }
  }, [calculateOffset])

  useEffect(() => {
    if (smooth && progressRef.current !== 0) {
      lastProgressRef.current = progressRef.current
      const start = new Date().getTime()
      const step = () => {
        const now = new Date().getTime()
        const ratio = Math.min((now - start) / duration, 1)
        progressRef.current = lastProgressRef.current + Math.max(0, (parsedProgress - lastProgressRef.current) * ratio)
        applyProgress()
        if (ratio < 1) {
          animationRef.current = requestAnimationFrame(step)
        }
      }
      animationRef.current = requestAnimationFrame(step)
    } else {
      progressRef.current = parsedProgress
      applyProgress()
    }
    return () => {
      animationRef.current && cancelAnimationFrame(animationRef.current)
    }
  }, [parsedProgress, applyProgress, duration, smooth])

  return (
    <div className={cls('relative bg-transparent', className)}>
      <svg width={size} height={size} className='-rotate-90 md:-rotate-90'>
        <circle
          className={cls('text-text stroke-border', baseCircleClassname)}
          cx='50%'
          cy='50%'
          r={radius}
          strokeWidth='4'
          fill='transparent'
        />
        <circle
          ref={svgCircleRef}
          className='text-text-interactive stroke-current'
          cx='50%'
          cy='50%'
          r={radius}
          strokeWidth='4'
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={String(calculateOffset())}
          fill='transparent'
        />
      </svg>
      <span
        className={cls(
          'absolute inset-0 flex items-center justify-center text-text font-bold text-heading-sm',
          progressClassname,
        )}
      >
        {text ? text : <span ref={spanRef}>{formatProgress(progressRef.current)}%</span>}
      </span>
    </div>
  )
}

export default RadialProgress
