import { TobPlan } from '@/types'
import { useCachedToBPricingTable } from './useTobPricingTable'
import { useMemo } from 'react'
import { API_TIER_IDS } from '@/constants'

export const API_TIER_ID_TO_FEATURES: Record<
  string,
  Array<{ name: string; included: boolean; tips?: string }>
> = {
  [API_TIER_IDS.ENTERPRISE_TRIAL]: [
    {
      name: 'Access to all the standard APIs',
      included: true,
    },
    {
      name: 'Trial price',
      included: true,
    },
    {
      name: '20 concurrent creations',
      included: true,
    },
    {
      name: 'Create Watermark-free videos',
      included: true,
    },
    {
      name: 'Create watermark-free images',
      included: true,
    },
    {
      name: 'Commercial use',
      included: true,
    },
    {
      name: 'Private creation',
      included: true,
    },
    {
      name: 'Top up as you go',
      included: true,
    },
  ],
  [API_TIER_IDS.ENTERPRISE_BASIC]: [
    {
      name: 'All features in Trial plan',
      included: true,
    },
    {
      name: 'Standard price',
      included: true,
    },
    {
      name: '40 concurrent creations',
      included: true,
    },
    {
      name: 'Top up as you go',
      included: true,
    },
  ],
  [API_TIER_IDS.ENTERPRISE_CUSTOMIZED]: [
    {
      name: 'All features in Basic plan',
      included: true,
    },
    {
      name: 'More concurrent creations',
      included: true,
    },
    {
      name: 'Customized features',
      included: true,
    },
  ],
}

const tierId2NameMap = {
  [API_TIER_IDS.ENTERPRISE_TRIAL]: 'Trial (beta)',
  [API_TIER_IDS.ENTERPRISE_BASIC]: 'Basic (beta)',
  [API_TIER_IDS.ENTERPRISE_CUSTOMIZED]: 'Customized Plan',
}

const useTobPlans = (): {
  data: TobPlan[]
  loading: boolean
} => {
  const { data: pricingTable, isValidating: pricingTableLoading } =
    useCachedToBPricingTable()

  const plans: TobPlan[] = useMemo(() => {
    const products = (pricingTable?.subcription_products ?? []).sort((a, b) =>
      String(a.tier_id)?.localeCompare(b.tier_id ?? ''),
    )
    return products.map((product) => {
      const defaultPrice = product?.prices?.find(
        (price) =>
          Boolean(product?.product?.default_price) &&
          price.id === product?.product?.default_price,
      )
      const firstActivePrice = product?.prices?.find((price) => price.active)

      const finalPrice = defaultPrice?.active ? defaultPrice : firstActivePrice

      return {
        ...product,
        is_free: false,
        name: tierId2NameMap[product?.tier_id] ?? product?.tier_name,
        disabled: !product?.product?.active,
        features: API_TIER_ID_TO_FEATURES[product.tier_id] ?? [],
        product_id: product?.product?.id,
        active_price: finalPrice ?? null,
      }
    })
  }, [pricingTable])

  return {
    data: plans,
    loading: pricingTableLoading,
  }
}

export default useTobPlans
