import { Skeleton as BaseSkeleton } from '@/components/ui/skeleton'
import { cls, whisper } from '@/utils'

export interface SkeletonProps {
  variant?: 'creation' | 'video'
  className?: string
}

export default function Skeleton({ variant = 'creation', className }: SkeletonProps) {
  if (variant === 'creation') {
    return (
      <div className={cls('size-full', className)}>
        <div className='size-full flex flex-col gap-2'>
          {/* title */}
          <div className='flex gap-2 items-center h-8'>
            <BaseSkeleton className='size-8 rounded-full' />
            <BaseSkeleton className='h-6 w-0 flex-1' />
          </div>
          {/* body */}
          <BaseSkeleton className='flex-1 w-full rounded-md aspect-video' />
          {/* footer */}
          <div className='flex items-center w-full h-8 justify-between rounded-md'>
            <BaseSkeleton className='h-full w-2/5 rounded-full' />
            <BaseSkeleton className='h-full w-1/4 rounded-full' />
          </div>
        </div>
      </div>
    )
  } else if (variant === 'video') {
    return (
      <div className={cls('size-full', className)}>
        <div className='size-full flex flex-col gap-2'>
          <BaseSkeleton className='flex-1 w-full rounded-md' />
          {/* footer */}
          <div className='flex items-center w-full h-8 justify-between rounded-md'>
            <BaseSkeleton className='h-full w-2/5 rounded-full' />
            <BaseSkeleton className='h-full w-1/4 rounded-full' />
          </div>
        </div>
      </div>
    )
  }
  return null
}
