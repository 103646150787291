'use client'

import IconCup2 from '@haiper/icons-svg/icons/solid/cup-2.svg'
import IconCar from '@haiper/icons-svg/icons/solid/car.svg'
import IconLaunch from '@haiper/icons-svg/icons/solid/launch.svg'
import Tag from '@/components/tag'
import RadialProgress from '@/components/radial-progress'
import { JobStatus } from '@/types'
import { cls, isProduction } from '@/utils'
import IconClockTimeHistory from '@haiper/icons-svg/icons/outline/clock-time-history.svg'
import { ReactElement, useMemo, useState } from 'react'
import Link from '../link'
import usePendingDisplay from './usePendingDisplay'
import Button from '../button'

interface PendingJobProps {
  creation: haiper.Creation
  jobStatus: JobStatus | null
  showMode: 'mobile' | 'grid' | 'single'
  className?: string
}

const queueTierTagClassName = 'absolute top-2 left-2'

const QueueTierMap: Record<string, ReactElement> = {
  relax: <Tag variant='primary' icon={IconCup2} text='Relax' className={queueTierTagClassName} />,
  fast: <Tag variant='primary' icon={IconCar} text='Fast' className={queueTierTagClassName} />,
  turbo: <Tag variant='primary' icon={IconLaunch} text='Turbo' className={queueTierTagClassName} />,
}

const PendingJob = ({ jobStatus, showMode, creation, className }: PendingJobProps) => {
  const estimatedTimeInMinutes = useMemo(() => {
    return jobStatus?.queued_esitmate_time ? Math.ceil((jobStatus?.queued_esitmate_time ?? 0) / 60) : null
  }, [jobStatus])

  const {
    showPending,
    showProgress,
    showEstimatedTime,
    estimatedTime,
    showUpgradeButtonForFastGeneration,
    showUpgradeLinkForRateLimit,
  } = usePendingDisplay({ creation, jobStatus })

  const [showDebugInfo, setShowDebugInfo] = useState(!isProduction)

  return (
    <div
      className={cls(
        'size-full relative border border-solid border-border rounded-lg flex justify-center items-center p-6 aspect-video',
        className,
      )}
    >
      <div className='w-60 min-w-60 md:w-105 h-45 max-h-full'>
        <div
          className={cls(
            'flex justify-center items-center flex-col bg-surface backdrop-blur-[10px] size-full relative rounded-lg',
            showMode === 'single' ? 'gap-4' : 'gap-2',
          )}
        >
          {showProgress ? (
            <div className={cls('flex flex-col gap-2 justify-center items-center')}>
              <RadialProgress
                smooth
                size={showMode === 'single' ? 48 : 48}
                className={cls('text-heading-xs', showMode === 'single' ? 'size-12' : 'size-12')}
                progress={Math.min(99, Math.round((jobStatus?.progress ?? 0) * 100) || 0)}
                duration={10000}
              />
              <div
                className={cls(
                  'text-text',
                  showMode === 'single' ? 'text-body-lg tracking-32' : 'text-body-md tracking-15',
                )}
              >
                Being generated
              </div>
            </div>
          ) : showPending ? (
            <div
              className={cls('flex flex-col items-center gap-2 justify-center', showMode === 'single' ? 'gap-4' : '')}
            >
              <IconClockTimeHistory
                className={cls('text-icon-subdued', showMode === 'single' ? 'size-12' : 'size-12')}
              />
              <div
                className={cls(
                  'text-text text-center',
                  showMode === 'single' ? 'text-body-lg tracking-32' : 'text-body-md tracking-15',
                )}
              >
                In Queue
                {showEstimatedTime && (
                  <div className={cls('flex flex-col items-center gap-2', showMode === 'single' ? 'gap-4' : '')}>
                    {estimatedTimeInMinutes ? (
                      <div className={cls('text-text text-center flex-col text-body-md tracking-15')}>
                        {!isProduction && showDebugInfo && (
                          <div className='italic text-text-subdued'>
                            (dev-only) Queued Position: {jobStatus?.queued_position ?? '--'}
                            <span
                              className='text-text-interactive cursor-pointer px-1 pl-2 not-italic'
                              onClick={() => setShowDebugInfo(false)}
                            >
                              Hide
                            </span>
                          </div>
                        )}
                        <div>Estimated wait time: {estimatedTime}</div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {showUpgradeButtonForFastGeneration && (
            <Button variant='primary' className='rounded-md h-8'>
              <Link href='/membership' className='px-1'>
                Upgrade for faster generations
              </Link>
            </Button>
          )}
          {showUpgradeLinkForRateLimit && (
            <div
              className={cls('flex flex-col items-center gap-2', showMode === 'single' ? 'gap-4' : '')}
              data-testid='rate-limit-upgrade'
            >
              <div className={cls('text-text-subdued text-center text-body-md tracking-15')}>
                <Button variant='link' className='p-0 h-max'>
                  <Link className='px-1 text-text-interactive' href='/membership'>
                    Upgrade
                  </Link>
                </Button>
                to create more tasks at once
              </div>
            </div>
          )}
        </div>
      </div>
      {QueueTierMap[creation?.queue_tier as string] ?? null}
    </div>
  )
}

export default PendingJob
