import { parallelRouteOpenAtom } from '@/atoms'
import { useAtomValue } from 'jotai'
import { usePathname, useRouter } from 'next/navigation'
import { useCallback, useMemo } from 'react'

export default function useNavigation() {
  const router = useRouter()
  const parallelRouteOpen = useAtomValue(parallelRouteOpenAtom)
  const pathname = usePathname()

  const isModalPathname = useMemo(() => {
    return (
      pathname.startsWith('/creation/') ||
      pathname.startsWith('/event/') ||
      pathname.startsWith('/spotlight/') ||
      pathname === '/submit' ||
      pathname === '/onboarding'
    )
  }, [pathname])

  const back = useCallback(() => {
    router.back()
  }, [router])

  const forward = useCallback(() => {
    router.forward()
  }, [router])

  const prefetch = useCallback(
    (path: string) => {
      router.prefetch(path)
    },
    [router],
  )

  const push = useCallback(
    (path: string, options?: { back?: boolean }) => {
      if (parallelRouteOpen && options?.back !== false && isModalPathname) {
        router.back()
        setTimeout(() => {
          router.push(path)
        }, 0)
      } else {
        router.push(path)
      }
    },
    [router, parallelRouteOpen, isModalPathname],
  )

  const replace = useCallback(
    (path: string) => {
      router.replace(path)
    },
    [router],
  )

  const refresh = useCallback(() => {
    router.refresh()
  }, [router])

  return {
    back,
    forward,
    prefetch,
    push,
    replace,
    refresh,
  }
}
