import { TobPlan } from '@/types'
import { useCachedTobCustomerInfo } from './useTobCustomerInfo'
import useTobPlans from './useTobPlans'

export interface UseActivePlanResult {
  data: TobPlan | null
  loading: boolean
}

const useActiveTobPlan = (): UseActivePlanResult => {
  const { data: customerInfo, isValidating: customerInfoLoading } =
    useCachedTobCustomerInfo()
  const { data: plans, loading: plansLoading } = useTobPlans()

  const loading = customerInfoLoading || plansLoading
  const activePlan =
    plans?.find(
      (plan) =>
        !!plan.product_id &&
        plan.product_id === customerInfo?.stripe_connect?.product,
    ) ?? null

  return {
    data: activePlan,
    loading,
  }
}

export default useActiveTobPlan
