import { getJobStatus } from '@/service/job.service'
import useService from '@banyudu/use-service'
import { JobStatus } from '@/types'
import { truthy } from '@/utils'

const useJobStatus = useService(getJobStatus, truthy, {
  keepPreviousData: false,
  refreshInterval(latestData: JobStatus | null | undefined) {
    if (!['succeed', 'failed'].includes(latestData?.status ?? '')) {
      return 1000 * 10 // 10s
    }
    return 0
  },
})

export default useJobStatus
