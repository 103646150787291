import { SOCIAL_ENTRIES } from '@/constants'
import { cls, openNewTab } from '@/utils'
import { useCallback } from 'react'
import IconArrowTopRight from '@haiper/icons-svg/icons/outline/arrow-top-right.svg'

const platformMap: Record<string, (typeof SOCIAL_ENTRIES)[0]> = SOCIAL_ENTRIES.reduce(
  (acc, entry) => {
    acc[entry.name] = entry
    return acc
  },
  {} as Record<string, (typeof SOCIAL_ENTRIES)[0]>,
)

export interface SocialPostBannerProps {
  post: haiper.SocialPost | null
  className?: string
}

export default function SocialPostBanner({ post, className }: SocialPostBannerProps) {
  const handleBannerClick = useCallback(() => {
    if (post?.url) {
      openNewTab(post.url)
    }
  }, [post])

  if (!post?.platform || !post?.url) {
    return null
  }

  const platform = platformMap[post.platform] ?? null

  const Icon = platform?.Icon
  const platformLabel = platform?.label
  if (!Icon) {
    return null
  }

  return (
    <div
      className={cls(
        'absolute inset-0 z-30 pointer-events-none top-2 h-max inset-x-2 bg-transparent flex flex-col gap-1 items-start justify-start text-sm ',
        className,
      )}
      aria-label='social post banner'
    >
      <div
        className={cls(
          'p-[6px] w-8 group-hover/video:w-full group-hover/video:bg-surface-primary group-hover/video:cursor-pointer h-8 cursor-default text-icon-on-color rounded-md bg-[rgb(18,18,23)]/80 flex gap-2 items-center pointer-events-auto transition-[width] duration-500 whitespace-nowrap overflow-x-hidden',
        )}
        onClick={handleBannerClick}
      >
        <Icon className='size-5 text-icon-on-color shrink-0' />
        <span className='text-text-on-color group-hover/video:block duration-75 flex-1'>{platformLabel}</span>
        <IconArrowTopRight className='size-5 text-icon-on-color shrink-0' />
      </div>
    </div>
  )
}
